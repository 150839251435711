/*
	Component that represents home page 
	of UC Berkeley Yang Gang website.
*/

import $ from 'jquery';

import React from 'react';

// Google Analytics
import ReactGA from 'react-ga';

// Firebase
import firebase from 'firebase';
import config from './../config';

import './UCBYangGangHome.css';

interface UCBYangGangHomeState {
	email: string,
	name: string,
	year: string,
	form: boolean
}

export class UCBYangGangHome extends React.Component<{}, UCBYangGangHomeState> {
	app: any;
	database: any;

	constructor(props: {}) {
		super(props);

		// Set up Firebase.
		if (!firebase.apps.length) {
			this.app = firebase.initializeApp(config);
		} else {
			this.app = firebase.apps[0];
		}
		this.database = this.app.database().ref().child('MailingList');

		// Initialize state.
		this.state = {
			email: '',
			name: '',
			year: '',
			form: true
		}

		// Method bindings.
		this.commitData = this.commitData.bind(this);
		this.handleFormInput = this.handleFormInput.bind(this);
		this.submitAnotherResponseButtonWasPressed = this.submitAnotherResponseButtonWasPressed.bind(this);

	}

	aboutButtonWasPressed() {
		const aboutContainer = document.getElementById('aboutContainer');
      	if (aboutContainer) {
        	aboutContainer.scrollIntoView({behavior: 'smooth'});
      	}
	}

	backButtonWasPressed() {
		const menuRow = window.document.getElementById('menuRow');
		if (menuRow) {
			menuRow.style.display = 'none';
		}

		const contentRows: string[] = ['homeRow', 'aboutRow', 'teamRow', 'joinRow', 'eventsRow'];
		contentRows.forEach(function(row: string) {
			const rowHTML = window.document.getElementById(row);
			if (rowHTML) {
				rowHTML.style.display = 'flex';
			}
		})
	}

	commitData() {
		if (this.state.email !== '' && this.state.name !== '' && this.state.year !== '') {
			let tempState = {
				name: this.state.name.toLowerCase(),
				email: this.state.email.toLowerCase(),
				year: this.state.year.toLowerCase() 
			}
			this.database.push().set(tempState);
			this.performCommitAnimationInitial();
		} else {
			this.handleIncorrectState();
		}	
	}

	componentDidMount() {
		// Google Analytics.
		ReactGA.initialize('UA-152758367-1');
		ReactGA.pageview(window.location.pathname + window.location.search);
	}

	eventsButtonWasPressed() {
		const eventsContainer = window.document.getElementById('eventsContainer');
		if (eventsContainer) {
			eventsContainer.scrollIntoView({behavior: 'smooth'});
		}
	}

	handleFormInput(e: any) {
		switch(e.target.getAttribute('id')) {
			case 'emailForm':
				const emailForm = window.document.getElementById('emailForm');
				if (emailForm) {
					emailForm.style.borderBottomColor = '#003366';
				}
				this.setState({
					email: e.target.value
				});
				break;
			case 'nameForm':
				const nameForm = window.document.getElementById('nameForm');
				if (nameForm) {
					nameForm.style.borderBottomColor = '#003366';
				}
				this.setState({
					name: e.target.value
				});;
				break;
			case 'yearForm':
				const yearForm = window.document.getElementById('yearForm');
				if (yearForm) {
					yearForm.style.borderBottomColor = '#003366';
				}
				this.setState({
					year: e.target.value
				});;
				break;
		}
	}

	handleIncorrectState() {
		const nameForm = window.document.getElementById('nameForm');
		const emailForm = window.document.getElementById('emailForm');
		const yearForm = window.document.getElementById('yearForm');
		if (nameForm && this.state.name === '') {
			nameForm.style.borderBottomColor = 'lightcoral';
		}
		if (emailForm && this.state.email === '') {
			emailForm.style.borderBottomColor = 'lightcoral';
		}
		if (yearForm && this.state.year === '') {
			yearForm.style.borderBottomColor = 'lightcoral';
		}
	}

	joinButtonWasPressed() {
		const joinContainer = window.document.getElementById('joinContainer');
		if (joinContainer) {
			joinContainer.scrollIntoView({ behavior: 'smooth'});
		}
	}

	performCommitAnimationInitial() {
		this.setState({
			email: '',
			name: '',
			year: '',
			form: false
		}, this.toggleDancingYang);
	}

	submitAnotherResponseButtonWasPressed() {
		this.setState({
			form: true
		}, this.toggleDancingYang);
	}

	teamButtonWasPressed() {
		const teamRow = window.document.getElementById('teamRow');
		if (teamRow) {
			teamRow.scrollIntoView({ behavior: 'smooth'})
		}
	}

	toggleDancingYang() {
		$('#submitAnotherResponseButton').toggle();
		$('#yangDancing').slideToggle(500);	
	}

	render() {
		return(
			<div className='container-fluid'>
				<div className='row' id='aboutRow'>
					<div className='col' id='aboutContainer'>
						<p id='aboutText'>
							<strong>
								Mobilizing UC Berkeley students behind Andrew Yang's vision 
								of Humanity First: <span className='humanityFirst'>Freedom Dividend, </span>
								<span className='humanityFirst'>Medicare for All, </span>
								<span className='humanityFirst'>Human Centered Capitalism.</span>
							</strong>
						</p>
					</div>
				</div>
				<div className='row' id='copyrightRow'>
					{/* &copy; UC Berkeley Yang Gang 2020 |  */}
					<a className='socialMediaLogo' id='emailLogo' href='mailto:berkeley.yang.gang@gmail.com'>Email Link</a>
					<a className='socialMediaLogo' id='slackLogo' href='https://gmail.us20.list-manage.com/track/click?u=cc938ab6afa6010677e6c39e9&id=4c87740b70&e=dfe8a70f35' rel='noopener noreferrer'target='_blank'>Slack Link</a>
					<a className='socialMediaLogo' id='facebookLogo' href='https://www.facebook.com/groups/535418713907664/' rel='noopener noreferrer'target='_blank'>Facebook Link</a>
					<a className='socialMediaLogo' id='instagramLogo' href='https://www.instagram.com/ucbyanggang/?hl=en' rel='noopener noreferrer'target='_blank'>Instagram Link</a>
					<a className='socialMediaLogo' id='twitterLogo' href='https://twitter.com/ucbyanggang' rel='noopener noreferrer' target='_blank'>Twitter Link</a>
				</div>
			</div>
		);
	}
}
