import React from 'react';

import { Switch, Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';

import { UCBYangGangHome } from './UCBYangGangHome/UCBYangGangHome';

export class App extends React.Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path='/' component={UCBYangGangHome} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
